import React from "react";
import chevronR from "../../Images/chevr.png";
import "../../dashboard.css";
import {motion} from "framer-motion";

import sessionBut from "../../Images/session-button.png";
import now from "../../Images/now-clock.png";
import other from "../../Images/other-clock.png";
import cancelses from "../../Images/cancel.png";
import passimg from "../../Images/pass.png";

import sundayImg from "../../Images/sundaybg.png";
import {useState} from "react";

import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/Components/ui/dialog";

import SessionsWraper from "../modals/SessionsWraper";
import {useGetBlockedDatesQuery} from "@/App/services/appointmentsApiSlice";
import moment from "moment";
import NewSessionWrapper from "../modals/NewSessions/NewSessionWrapper";
import ErrorModal from "./ErrorModal";
import SessionInfo from "../modals/Sessions/SessionInfo";
import {useNavigate} from "react-router-dom";

function Calendar({
                      calendarData,
                      filteredData,
                      today,
                      month,
                      year,
                      dateToString,
                  }) {
    const [openModal, setModal] = useState(false);
    const [sessionDetails, setSessionDetails] = useState(null)
    const [openNewSessionModal, setNewSessionModal] = useState(false);
    const [error, setError] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [moreDetails, setMoreDetails] = useState(null)

    const {data: blockedDates} = useGetBlockedDatesQuery();

    function getDates(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate);
        stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    function flatten(arr) {
        return arr?.reduce(function (flat, toFlatten) {
            return flat?.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
        }, []);
    }

    const getBlockedDaysArray = blockedDates && blockedDates?.data?.map((date) => {
        const days = getDates(date.from_date, date.to_date);
        return flatten(days)
    })

    const blockedDays = flatten(getBlockedDaysArray);

    // const handleNewSession = (e, dayInfo) => {
    //   e.stopPropagation()
    //   if(moment(dayInfo.dateString).isBefore() && moment(dayInfo.dateString).isSameOrBefore()){
    //     return setError(prev => !prev)
    //   };
    //   setNewSessionModal(prev => !prev)
    // }

    const handleNewSession = (e, dayInfo) => {
        e.stopPropagation();
        if (moment(dayInfo.dateString).isBefore(moment(), "day")) {
            return setError(prev => !prev);
        }
        setNewSessionModal(prev => !prev);
    };


    function handleSessionModal(e, value) {
        e.stopPropagation();
        setSessionDetails(() => value)
        setModal(!openModal);
    }

    const handleShowMoreCalData = (calData, formattedDate) => {
        setMoreDetails(() => calData)
        setShowMore((prev) => !prev);
        setError(false)
    }

    const variants = {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.5,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                ease: "easeOut",
                duration: 0.5,
            },
        },
    };

    return (
        <motion.div
            key="calview"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
            className="w-full overflow-x-scroll"
        >
            <MoreInfoModal
                showMore={showMore}
                setShowMore={setShowMore}
                calData={moreDetails}
                handleSessionModal={handleSessionModal}
            />
            <SessionInfo
                openModal={openModal}
                setModal={setModal}
                sessionDetails={sessionDetails}
            />
            <NewSessionWrapper
                openModal={openNewSessionModal}
                setModal={setNewSessionModal}
            />
            <ErrorModal openModal={error} setModal={setError}/>
            <div
                id="appointment"
                className="w-full min-w-[1000px] rounded-[16px]"
            >
                <table className="w-full border-collapse">
                    <thead>
                    <tr className="*:w-[14.287%] bg-[#F6F8FA] text-[#868C98] text-xs font-medium *:uppercase *:py-2">
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                    </tr>
                    </thead>
                    <tbody>
                    {calendarData?.map((week, index) => (
                        <tr className="h-[140px] " key={index}>
                            {week?.map((dayInfo, index) => {
                                let date;
                                let sunCh;
                                let sunday;
                                let block;
                                let blockedDay;
                                if (dayInfo.isPrevMonth && dayInfo.day > 14) {
                                    if (month === 0) {
                                        date = dateToString(year - 1, 11, dayInfo.day);
                                    } else {
                                        date = dateToString(year, month - 1, dayInfo.day);
                                    }
                                    sunCh = new Date(date);

                                    if (sunCh.getDay() === 0) {
                                        sunday = true;
                                    }
                                } else {
                                    date = dayInfo.dateString;
                                    sunday = dayInfo.isSunday;
                                }
                                block = moment(dayInfo.dateString).format('YYYY-MM-DD')
                                if (blockedDays?.includes(block)) {
                                    blockedDay = true
                                }

                                const dateObject = new Date(date);

                                const formattedDate = dateObject.toLocaleDateString("en-US", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                });

                                return (
                                    <td
                                        key={index}
                                        className={`bg-white relative overflow-hidden ${blockedDay ? 'pointer-events-none' : ''} `}
                                    >

                                        <div className="!w-full h-[135px] p-2 relative z-10 flex flex-col justify-start items-center">
                                            {/* Background Click Area */}
                                            <div
                                                onClick={(e) => handleNewSession(e, dayInfo)}
                                                className="size-full absolute z-[-1] cursor-pointer hover:bg-gray-50"
                                                aria-label="Open new session modal"
                                            ></div>

                                            {/* Day Circle */}
                                            <p
                                                className={` ${
                                                    date === today ? 'bg-[#1A6A73] text-white m-2' : 'text-[#525866]'
                                                } p-1 aspect-square text-center rounded-full text-xs leading-5 font-medium cursor-pointer hover:scale-105 transition-transform`}
                                                aria-label={`Day: ${dayInfo.day}`}
                                            >
                                                {dayInfo.day}
                                            </p>

                                            {/* Calendar Data */}
                                            {(() => {
                                                const calData =
                                                    filteredData && filteredData?.filter((item) => item.date === date);

                                                const more = calData?.length > 2;

                                                return (
                                                    <div className="w-full">
                                                        {calData &&
                                                            calData.slice(0, 2).map((item, index) => {
                                                                const NewstartTime = moment(item?.startTime)
                                                                    .subtract(5, 'minutes')
                                                                    .format();
                                                                const endTime = moment(NewstartTime)
                                                                    .add(item?.duration + 15, 'minutes')
                                                                    .format();
                                                                const isTimeNow =
                                                                    moment().isSameOrAfter(NewstartTime) &&
                                                                    moment().isSameOrBefore(endTime);

                                                                return (
                                                                    <div
                                                                        onClick={(e) => handleSessionModal(e, item)}
                                                                        key={index}
                                                                        className="mt-2 relative w-full h-[24px] cursor-pointer overflow-hidden whitespace-nowrap flex items-center"
                                                                        tabIndex={0}
                                                                        role="button"
                                                                        aria-label={`Session: ${item.name}`}
                                                                    >
                                                                        <div
                                                                            className={`text-xs w-full absolute top-0 left-0 flex justify-center items-center gap-[6px] py-1 px-2 rounded-[4px] transition-all ${
                                                                                isTimeNow && item.status !== 'Cancelled'
                                                                                    ? 'bg-[#CBF5E5] hover:bg-[#b3e6d1]'
                                                                                    : item.status === 'Completed'
                                                                                        ? 'bg-[#F6F8FA] text-[#525866] hover:bg-gray-100'
                                                                                        : item.status === 'Upcoming' || item.status === 'Confirmed'
                                                                                            ? 'bg-[#F6F8FA] text-[#525866] hover:bg-gray-100'
                                                                                            : item.status === 'Cancelled'
                                                                                                ? 'bg-[#ffcac2f6] text-[#6E330C] hover:bg-[#ffb3a8]'
                                                                                                : item.status === 'Awaiting_Approval'
                                                                                                    ? 'bg-[#FBDFB1] text-[#6E330C] hover:bg-[#f5cf92]'
                                                                                                    : 'bg-[#0000002b] text-[#5654539d] hover:bg-gray-300'
                                                                            }`}
                                                                        >
                                                                            <span className="font-normal">{item.time}</span>
                                                                            <span className="font-[500] capitalize whitespace-nowrap overflow-ellipsis">
                                                                                {item.name}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                        {/* Show More Button */}
                                                        {more && (
                                                            <button
                                                                onClick={() => handleShowMoreCalData(calData, formattedDate)}
                                                                className="flex w-full justify-start mt-2 items-center gap-1 cursor-pointer text-[13px] font-medium text-[#525866] hover:text-[#1A6A73] transition-colors"
                                                                aria-label={`Show ${calData.length - 2} more sessions`}
                                                            >
                                                                <p>{calData.length - 2} More</p>
                                                                <img
                                                                    src={chevronR}
                                                                    className="w-[12px] hover:rotate-90 transition-transform"
                                                                    alt="Chevron Right"
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                        <img
                                            src={sundayImg}
                                            alt="no work"
                                            className={`w-full ${
                                                blockedDay ? "inline" : "hidden"
                                            } absolute top-0 left-0 min-h-full z-1`}
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </motion.div>
    );
}

export default Calendar;

const MoreInfoModal = ({calData, formattedDate, showMore, setShowMore, handleSessionModal}) => {

    const navigate = useNavigate()

    return (
        <Dialog open={showMore} onOpenChange={setShowMore}>
            {/* <DialogTrigger asChild> */}

            {/* </DialogTrigger> */}
            <DialogContent
                style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                }}
                className="sm:max-w-[425px] max-h-[80vh] overflow-y-scroll"
            >
                <DialogHeader>
                    <DialogTitle>
                        {/* {formattedDate} */}
                        {moment(calData?.[0]?.date).format("dddd, MMMM Do YYYY")}
                    </DialogTitle>
                </DialogHeader>
                <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-1 mb-3"/>
                <div className="flex w-full flex-col justify-center items-center gap-2">
                    {calData?.map((data, index) => {

                        const NewstartTime = moment(data?.startTime).subtract(5, 'minutes').format()
                        const endTime = moment(NewstartTime).add(data?.duration + 15, 'minutes').format();
                        let isTimeNow;

                        if (moment().isSameOrAfter(NewstartTime) && moment().isSameOrBefore(endTime)) {
                            isTimeNow = true
                        }

                        return <div
                            key={index}
                            onClick={(e) =>
                                handleSessionModal(e, data)
                            }
                            className="pt-4 px-2 w-full cursor-pointer bg-white pb-2 space-y-3 border border-[#E2E4E9] rounded-[12px]"
                        >
                            <div className="px-2 flex flex-col justify-start items-start">
                                <div className="flex justify-between w-full items-center">
                                    <h4 className="font-medium capitalize text-[14px] leading-5 text-[#0A0D14]">
                                        {data?.name}
                                    </h4>
                                    <div className="flex justify-center items-center gap-[6px] ">
                                        <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#FBDFB1] rounded-[50px] px-2 py-[2px]">
                                            {data.status === "Not_Started" ? 'Not Started' : data.status === "No_Show" ? "No Show" : data.status}
                                        </p>
                                        <img
                                            className="w-[24px]"
                                            src={sessionBut}
                                            alt="view"
                                        />
                                    </div>
                                </div>
                                <p className="text-[#525866] text-[12px] font-normal leading-4 pt-[2px]">
                                    {data?.time}
                                </p>
                            </div>
                            <div
                                className={`${
                                    isTimeNow && data.status !== 'Cancelled' ? "bg-[#CBF5E5]" : data.status === "Completed" ? "bg-[#F6F8FA] text-[#525866]"
                                        :
                                        data.status === "Upcoming" || data.status === "Confirmed" ? "bg-[#F6F8FA] text-[#525866]"
                                            :
                                            data.status === "Cancelled" ? "bg-[#ffcac2f6] text-[#6E330C]"
                                                :
                                                data.status === 'Awaiting_Approval' ? "bg-[#FBDFB1] text-[#6E330C]"
                                                    : "bg-[#0000002b] text-[#5654539d]"
                                } p-2 rounded-[8px] flex justify-between items-center`}
                            >
                                <div className="flex justify-center items-center gap-1">
                                    <img
                                        src={
                                            isTimeNow && data.status !== 'Cancelled' ? now
                                                : data.status === "Cancelled" || data.status === "no show"
                                                    ? cancelses
                                                    : data.status === "Completed"
                                                        ? passimg
                                                        : other
                                        }
                                        className="w-4"
                                        alt="clock"
                                    />
                                    <p className={` ${data.status === 'Cancelled' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        Cancelled
                                    </p>
                                    <p className={` ${data.status === 'Upcoming' || data.status === 'Confirmed' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        {(moment(data?.startTime).endOf('').fromNow())}
                                    </p>
                                    <p className={` ${data.status === 'Completed' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        Completed
                                    </p>
                                    <p className={` ${data.status === 'Awaiting_Approval' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        Awaiting_Approval
                                    </p>
                                    <p className={` ${data.status === 'Not_Started' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        Not Started
                                    </p>
                                    <p className={` ${data.status === 'No_Show' ? 'block' : 'hidden'} text-[12px] leading-4 text-[#0A0D14] `}>
                                        No Show
                                    </p>
                                </div>

                                {isTimeNow && data.status !== 'Cancelled' ? (
                                    <p onClick={(event) => {
                                        event.stopPropagation();
                                        navigate(`/video-chat/${data?.id}`, {state: data})
                                    }} className="z-40 underline font-medium text-[12px] leading-4 text-[#176448] cursor-pointer">
                                        Start Call
                                    </p>
                                ) : (
                                    <p className="text-[#525866] text-[12px] leading-4">
                                        {data.date}
                                    </p>
                                )}
                            </div>
                        </div>
                    })}
                </div>
            </DialogContent>
        </Dialog>

    )
}

