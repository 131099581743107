import { useState } from "react";

function usePINValidator() {
    const [errorMessage, setErrorMessage] = useState("");

    const validatePIN = (pin) => {
        setErrorMessage("");

        const hasLowercase = /[a-z]/.test(pin);
        const hasUppercase = /[A-Z]/.test(pin);
        const hasDigit = /\d/.test(pin);
        const hasSpecialChar = /[@$!%*?&.]/.test(pin);
        const isValidLength = pin.length >= 8;

        if (!hasLowercase) return "Password must contain at least one lowercase letter.";
        if (!hasUppercase) return "Password must contain at least one uppercase letter.";
        if (!hasDigit) return "Password must contain at least one digit.";
        if (!hasSpecialChar) return "Password must contain at least one special character.";
        if (!isValidLength) return "Password must be at least 8 characters long.";

        return "";
    };

    return { errorMessage, validatePIN };
}

export default usePINValidator;
