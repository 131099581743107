import axios from "axios";

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
});

const getCoursesApi = (data) => {
  const url = "/web/wellness-library/courses/list";
  return api.get(url, data);
};

const getCourseContentById = (courseId) => {
  const url = `/web/wellness-library/courses/show/${courseId}`;
  return api.get(url);
};

const getCountries = () => {
  const url = `https://restcountries.com/v3.1/all?fields=name,capital,flags,demonyms`;
  return api.get(url);
};

const getCountriesCodes = () => {
  const url = `https://restcountries.com/v3.1/all`;
  return api.get(url);
};

const fetchTermsAndConditions = (data) => {
  const url = "/web/terms-and-conditions";
  return api.get(url, data);
};

const fetchPrivacyPolicy = (data) => {
  const url = "/web/privacy-policies";
  return api.get(url, data);
};

const submitContact = (data) => {
  const url = "/web/contact-us";
  return api.post(url, data);
};

const submitTherapistSignup = (data) => {
  const url = "/auth/therapy-register";
  return api.post(url, data);
};
const checkEmail = (data) => {
  const url = "/auth/login/preview";
  return api.post(url, data);
};
const checkOtp = (data) => {
  const url = "/auth/otp/request";
  return api.post(url, data);
};
const checkPhoneNumber = (data) => {
  const url = "/general/location/country-phone-numbers";
  return api.get(url, data);
};

const fetchTestimonialsApi = () => {
  const url = "/web/reviews";
  return api.get(url);
};

export {
  getCoursesApi,
  getCourseContentById,
  fetchTermsAndConditions,
  fetchPrivacyPolicy,
  submitContact,
  fetchTestimonialsApi,
  getCountries,
  submitTherapistSignup,
  getCountriesCodes,
  checkEmail,
  checkPhoneNumber,
  checkOtp,
};
export default api;
