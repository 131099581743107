import {useState, useRef, useEffect} from 'react';

const useAudioRecording = (baseUrl, sessionId) => {
    const [audioURL, setAudioURL] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const startRecording = async () => {
        if (isRecording) {
            console.warn('Recording is already in progress.');
            return;
        }

        try {
            if (!navigator.mediaDevices || !window.MediaRecorder) {
                throw new Error('Browser does not support audio recording.');
            }

            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, {type: 'audio/webm'});
                const url = URL.createObjectURL(audioBlob);
                setAudioURL(url);
                audioChunksRef.current = [];

                // Save recording automatically
                await saveRecording(audioBlob);
            };

            mediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            mediaRecorderRef.current = null;
        }
    };

    const saveRecording = async (audioBlob) => {
        if (!audioBlob) {
            console.warn('No audio blob available to save.');
            return;
        }

        setIsSaving(true);

        try {
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.webm');
            formData.append('sessionId', sessionId);

            const response = await fetch(`${baseUrl}/azure-upload-audio`, {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json',
                },
            });

            const responseData = await response.json();
            console.log('Response Data:', responseData);

            if (response.ok) {
                console.log('Audio saved successfully!', responseData);
                setAudioURL(null);
            } else {
                console.error('Failed to save the audio:', responseData);
            }
        } catch (error) {
            console.error('Error saving the audio:', error);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
                mediaRecorderRef.current = null;
            }
        };
    }, []);

    return {isSaving, startRecording, stopRecording};
};

export default useAudioRecording;