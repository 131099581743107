import React, {useEffect, useState} from 'react'
import {Storage} from '@/App/api/storage';
import {useCreateSessionNoteMutation} from '@/App/services/sessionSlice';
import {FormLoader} from '@/Components/loader';
import {Textarea} from '@/Components/ui/textarea';
import {toast} from 'sonner';
import {handleError} from '@/App/utils/renderErrors';

const noteKeys = [
    'assessment_interventions', 'assessment_progress', 'assessment_observations',
    'subjective_history', 'subjective_psychological', 'subjective_relevant',
    'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary'
]

// eslint-disable-next-line react/prop-types
const SessionNote = ({sessionId}) => {
    const [note, setNote] = useState({
        assessment_interventions: '',
        assessment_progress: '',
        assessment_observations: '',
        subjective_history: '',
        subjective_psychological: '',
        subjective_relevant: '',
        objective_appearance: '',
        objective_behavior: '',
        objective_symptoms: '',
        objective_risk: '',
        summary: '',

        home_work: '',
        plans_for_future_session: '',
        plans_for_continued_treatment: '',
    });

    const [createSessionNote, {isLoading}] = useCreateSessionNoteMutation();

    const handleCreateSessionNote = async (event) => {
        event.preventDefault();
        try {
            const finalNote = {
                session_id: sessionId,
                ...note
            };
            await createSessionNote({...finalNote}).unwrap();
            toast.success("Session Note saved successfully");
        } catch (error) {
            const errorMessage = handleError(error);
            toast.error(errorMessage);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        Storage.setItem([e.target.name], value);
        setNote({
            ...note,
            [e.target.name]: value
        });
    };

    useEffect(() => {
        const loadedNotes = noteKeys.reduce((acc, key) => {
            acc[key] = Storage.getItem(key) || '';
            return acc;
        }, {});
        setNote((prev) => ({...prev, ...loadedNotes}));
    }, []);

    return (
        <form onSubmit={handleCreateSessionNote} className='w-full flex flex-col items-start'>
            <header className='w-full flex items-center justify-between sticky top-0 bg-white p-5 shadow-md border-b border-gray-300'>
                <p className='text-xl font-bold text-gray-800'>Session Notes</p>
                <button type='submit' className="rounded-full bg-[#242628] text-[#ffffff] text-sm py-2 px-6 font-semibold">
                    {isLoading ? <FormLoader/> : 'Save Note'}
                </button>
            </header>

            <main className='w-full p-6 flex flex-col gap-12 bg-gray-50'>

                {/* Section: Subjective */}
                <Section title="Subjective">
                    <SessionTextArea
                        label="History of Presenting Complaint"
                        name='subjective_history'
                        placeholder="History of complaint here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.subjective_history}
                    />
                    <SessionTextArea
                        label="Psychological Factors"
                        name='subjective_psychological'
                        placeholder="Note psychological factors here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.subjective_psychological}
                    />
                    <SessionTextArea
                        label="Relevant Personal and Social History"
                        name='subjective_relevant'
                        placeholder="Social history..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.subjective_relevant}
                    />
                </Section>

                {/* Section: Objective */}
                <Section title="Objective">
                    <SessionTextArea
                        label="Appearance"
                        name='objective_appearance'
                        placeholder="Note appearance..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.objective_appearance}
                    />
                    <SessionTextArea
                        label="Behavior"
                        name='objective_behavior'
                        placeholder="Behavior..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.objective_behavior}
                    />
                    <SessionTextArea
                        label="Symptoms Observed"
                        name='objective_symptoms'
                        placeholder="Symptoms observed..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.objective_symptoms}
                    />
                    <SessionTextArea
                        label="Risk Assessment"
                        name='objective_risk'
                        placeholder="Risk assessment..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.objective_risk}
                    />
                </Section>

                {/* Section: Assessment */}
                <Section title="Assessment">
                    <SessionTextArea
                        label="Interventions"
                        name='assessment_interventions'
                        placeholder="Note your interventions here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.assessment_interventions}
                    />
                    <SessionTextArea
                        label="Progress and Response"
                        name='assessment_progress'
                        placeholder="Note the responses here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.assessment_progress}
                    />
                    <SessionTextArea
                        label="Observations"
                        name='assessment_observations'
                        placeholder="Note your observations here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.assessment_observations}
                    />
                </Section>

                {/* Plan (To be shared with the Client)  */}
                <Section title="Plan (To be shared with the Client)">
                    <SessionTextArea
                        label="Homework"
                        name='home_work'
                        placeholder="Homework..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.home_work}
                    />
                    <SessionTextArea
                        label="Plans for Future Session"
                        name='plans_for_future_session'
                        placeholder="Plans for Future Session..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.plans_for_future_session}
                    />
                    <SessionTextArea
                        label="Plans for Continued Treatment"
                        name='plans_for_continued_treatment'
                        placeholder="Plans for Continued Treatment..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.plans_for_continued_treatment}
                    />
                </Section>

                {/* Section: Summary */}
                <Section title="Summary">
                    <SessionTextArea
                        label="Summary"
                        name='summary'
                        placeholder="Summary goes here..."
                        onChange={handleInputChange}
                        rows={5}
                        value={note.summary}
                    />
                </Section>
            </main>
        </form>
    );
};

// eslint-disable-next-line react/prop-types
const Section = ({title, children}) => (
    <div className="w-full flex flex-col gap-4">
        <header className="text-xl font-semibold text-gray-700 border-b border-[#14595F] pb-2">{title}</header>
        <div className="w-full flex flex-col gap-6">{children}</div>
    </div>
);

// eslint-disable-next-line react/prop-types
const SessionTextArea = ({label, name, placeholder, onChange, value, rows}) => (
    <div className="flex flex-col w-full">
        <label htmlFor={name} className="text-base font-medium text-gray-600">{label}</label>
        <Textarea
            id={name}
            name={name}
            rows={rows}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="mt-2 p-4 border border-[#14595F] rounded-md shadow-sm focus:ring-[#14595F] focus:border-[#14595F]"
        />
    </div>
);

export default SessionNote;


// import React, { useEffect, useState } from 'react'
// import { Storage } from '@/App/api/storage';
// import { useCreateSessionNoteMutation } from '@/App/services/sessionSlice';
// import { FormLoader } from '@/Components/loader';
// import { Textarea } from '@/Components/ui/textarea';
// import { toast } from 'sonner';
// import { handleError } from '@/App/utils/renderErrors';
//
// const noteKeys = [
//     'assessment_interventions', 'assessment_progress', 'assessment_observations',
//     'subjective_history', 'subjective_psychological', 'subjective_relevant',
//     'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary'
// ]
//
// const SessionNote = ({ sessionId }) => {
//
//     const [note, setNote] = useState({
//         assessment_interventions: '',
//         assessment_progress: '',
//         assessment_observations: '',
//         subjective_history: '',
//         subjective_psychological: '',
//         subjective_relevant: '',
//         objective_appearance: '',
//         objective_behavior: '',
//         objective_symptoms: '',
//         objective_risk: '',
//         summary: '',
//     });
//
//     const [ createSessionNote, { isLoading } ] = useCreateSessionNoteMutation();
//
//     const handleCreateSessionNote = async (event) => {
//         event.preventDefault()
//         try {
//             const finalNote = {
//                 session_id: sessionId,
//                 ...note
//             }
//             const res = await createSessionNote({...finalNote}).unwrap();
//
//             toast.success("Session Note saved successfully")
//
//         } catch(error){
//             const errorMessage = handleError(error);
//             toast.error(errorMessage);
//         }
//     }
//
//     const handleInputChange = (e) => {
//         const value = e.target.value;
//         Storage.setItem([e.target.name], value)
//         setNote({
//             ...note,
//             [e.target.name]: value
//         });
//     };
//
//     const handleKeyDown = (e, field) => {
//         // Trigger save on "Enter" or "Arrow Up" key press
//         console.log('key release ----', e.key)
//         console.log('key release ----', e.key === 'keyup')
//
//         if (e.key === "Enter" || e.key === "On") {
//             handleCreateSessionNote(e);
//         }
//     };
//
//     useEffect(() => {
//         const assessment_interventions = Storage.getItem('assessment_interventions');
//         const assessment_progress = Storage.getItem('assessment_progress');
//         const assessment_observations = Storage.getItem('assessment_observations');
//         const subjective_history = Storage.getItem('subjective_history');
//         const subjective_psychological = Storage.getItem('subjective_psychological');
//         const subjective_relevant = Storage.getItem('subjective_relevant');
//         const objective_appearance = Storage.getItem('objective_appearance');
//         const objective_behavior = Storage.getItem('objective_behavior');
//         const objective_symptoms = Storage.getItem('objective_symptoms');
//         const objective_risk = Storage.getItem('objective_risk');
//         const summary = Storage.getItem('summary');
//
//         setNote((prev) => {
//             return {
//                 ...prev,
//                 assessment_interventions,
//                 assessment_progress,
//                 assessment_observations,
//                 subjective_history,
//                 subjective_psychological,
//                 subjective_relevant,
//                 objective_appearance,
//                 objective_behavior,
//                 objective_symptoms,
//                 objective_risk,
//                 summary
//             }
//         })
//     }, [])
//
//     //   console.log(note)
//
//   return (
//     <form onSubmit={handleCreateSessionNote} className='w-full flex items-start flex-col'>
//         <header className='w-full flex items-center justify-between sticky border-b border-[#E2E4E9] top-0 bg-white p-5'>
//             <p className='text-xl font-extrabold'>
//                 Sessions Note
//             </p>
//
//                 <button type='submit' className="rounded-[50px] border bg-[#242628] text-[#ffffff] text-sm  py-[10px] font-semibold px-4">
//                     {isLoading ? <FormLoader /> : 'Save Note'}
//                 </button>
//             </header>
//
//             <main className='w-full p-5 flex flex-col gap-8'>
//                 <section className='w-full flex items-start flex-col gap-6'>
//                     <header className='text-xl font-semibold'>Summary</header>
//                     <SessionTextArea
//                         label="Summary"
//                         name='summary'
//                         placeholder="Summary goes here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'summary')}
//                         rows={5}
//                         value={note.summary}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                 </section>
//
//                 <section className='w-full flex items-start flex-col gap-6'>
//                     <header className='text-xl font-semibold'>Assessment</header>
//                     <SessionTextArea
//                         label="Interventions"
//                         name='assessment_interventions'
//                         placeholder="Note your interventions here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'assessment_interventions')}
//                         rows={5}
//                         value={note.assessment_interventions}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//
//                     <SessionTextArea
//                         label="Progress and Response"
//                         name='assessment_progress'
//                         placeholder="Note the responses here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'assessment_progress')}
//                         rows={5}
//                         value={note.assessment_progress}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                     <SessionTextArea
//                         label="Observations"
//                         name='assessment_observations'
//                         placeholder="Note your observations here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'assessment_observations')}
//                         rows={5}
//                         value={note.assessment_observations}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                 </section>
//
//                 <section className='w-full flex items-start flex-col gap-3'>
//                     <header className='text-xl font-semibold'>Subjective</header>
//                     <SessionTextArea
//                         label="History of Presenting complaint"
//                         name='subjective_history'
//                         placeholder="History of complaint here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'subjective_history')}
//                         rows={5}
//                         value={note.subjective_history}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//
//                     <SessionTextArea
//                         label="Psychological Factors"
//                         name='subjective_psychological'
//                         placeholder="Note psychological factors here..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'subjective_psychological')}
//                         rows={5}
//                         value={note.subjective_psychological}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                     <SessionTextArea
//                         label="Relevant Personal and Social History"
//                         name='subjective_relevant'
//                         placeholder="Social history..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'subjective_relevant')}
//                         rows={5}
//                         value={note.subjective_relevant}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                 </section>
//
//                 <section className='w-full flex items-start flex-col gap-3'>
//                     <header className='text-xl font-semibold underline'>Objective</header>
//                     <SessionTextArea
//                         label="Appearance"
//                         name='objective_appearance'
//                         placeholder="Note appearance..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'objective_appearance')}
//                         rows={5}
//                         value={note.objective_appearance}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//
//                     <SessionTextArea
//                         label="Behavior"
//                         name='objective_behavior'
//                         placeholder="Behavior..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'objective_behavior')}
//                         rows={5}
//                         value={note.objective_behavior}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                     <SessionTextArea
//                         label="Symptoms Observed"
//                         name='objective_symptoms'
//                         placeholder="Symptoms observed..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'objective_symptoms')}
//                         rows={5}
//                         value={note.objective_symptoms}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                     <SessionTextArea
//                         label="Risk Assessment"
//                         name='objective_risk'
//                         placeholder="Risk assessment..."
//                         onChange={handleInputChange}
//                         onKeyUp={(e) => handleKeyDown(e, 'objective_risk')}
//                         rows={5}
//                         value={note.objective_risk}
//                         onBlur={(e) => handleCreateSessionNote(e)}
//                     />
//                 </section>
//             </main>
//         </form>
//     )
// }
//
// const SessionTextArea = ({ label, name, placeholder, onChange, value, rows, onKeyUp, onBlur }) => {
//     return (
//         <div className="flex flex-col w-full">
//             <label htmlFor={name} className="text-base">{label}</label>
//             <Textarea
//                 id={name}
//                 name={name}
//                 rows={rows}
//                 value={value}
//                 onChange={onChange}
//                 placeholder={placeholder}
//                 onKeyUp={onKeyUp}
//                 onBlur={onBlur}
//                 className="mt-3 p-4 border rounded-md"
//             />
//         </div>
//     );
// }
//
// export default SessionNote;


// import React, { useEffect, useState } from 'react'
// import { Storage } from '@/App/api/storage';
// import { useCreateSessionNoteMutation } from '@/App/services/sessionSlice';
// import { FormLoader } from '@/Components/loader';
// import { Textarea } from '@/Components/ui/textarea';
// import { toast } from 'sonner';
// import { handleError } from '@/App/utils/renderErrors';
//
// const noteKeys = [
//     'assessment_interventions', 'assessment_progress', 'assessment_observations',
//     'subjective_history', 'subjective_psychological', 'subjective_relevant',
//     'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary'
// ]
//
// const SessionNote = ({ sessionId }) => {
//
//     const [note, setNote] = useState({
//         assessment_interventions: '',
//         assessment_progress: '',
//         assessment_observations: '',
//         subjective_history: '',
//         subjective_psychological: '',
//         subjective_relevant: '',
//         objective_appearance: '',
//         objective_behavior: '',
//         objective_symptoms: '',
//         objective_risk: '',
//         summary: '',
//     });
//
//     const [ createSessionNote, { isLoading } ] = useCreateSessionNoteMutation();
//
//     const handleCreateSessionNote = async (event) => {
//         event.preventDefault()
//         try {
//             const finalNote = {
//                 session_id: sessionId,
//                 ...note
//             }
//             const res = await createSessionNote({...finalNote}).unwrap();
//
//             toast.success("Session Note saved successfully")
//
//         } catch(error){
//             const errorMessage = handleError(error);
//             toast.error(errorMessage);
//         }
//     }
//
//     const  handleInputChange = (e) => {
//         const value = e.target.value;
//         Storage.setItem([e.target.name], value)
//         setNote({
//             ...note,
//             [e.target.name]: value
//         });
//       };
//
//       useEffect(() => {
//
//         const assessment_interventions = Storage.getItem('assessment_interventions');
//         const assessment_progress = Storage.getItem('assessment_progress');
//         const assessment_observations = Storage.getItem('assessment_observations');
//         const subjective_history = Storage.getItem('subjective_history');
//         const subjective_psychological = Storage.getItem('subjective_psychological');
//         const subjective_relevant = Storage.getItem('subjective_relevant');
//         const objective_appearance = Storage.getItem('objective_appearance');
//         const objective_behavior = Storage.getItem('objective_behavior');
//         const objective_symptoms = Storage.getItem('objective_symptoms');
//         const objective_risk = Storage.getItem('objective_risk');
//         const summary= Storage.getItem('summary');
//
//         setNote((prev) => {
//             return {
//                 ...prev,
//                 assessment_interventions,
//                 assessment_progress,
//                 assessment_observations,
//                 subjective_history,
//                 subjective_psychological,
//                 subjective_relevant,
//                 objective_appearance,
//                 objective_behavior,
//                 objective_symptoms,
//                 objective_risk,
//                 summary
//             }
//         })
//       }, [])
//
//   return (
//     <form onSubmit={handleCreateSessionNote} className='w-full flex items-start flex-col'>
//         <header className='w-full flex items-center justify-between sticky border-b border-[#E2E4E9] top-0 bg-white p-5'>
//             <p className='text-2xl font-extrabold'>
//                 Sessions Note
//             </p>
//
//             <button type='submit' className="rounded-[50px] border bg-[#242628] text-[#ffffff] text-sm  py-[10px] font-semibold px-4">
//                 {isLoading ? <FormLoader /> : 'Save Note'}
//             </button>
//         </header>
//
//         <main className='w-full p-5 flex flex-col gap-8'>
//             <section className='w-full flex items-start flex-col gap-6'>
//                 <header className='text-2xl font-semibold'>Summary</header>
//                 <SessionTextArea
//                     label="Summary"
//                     name='summary'
//                     placeholder="Summary goes here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.summary}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//             </section>
//
//             <section className='w-full flex items-start flex-col gap-6'>
//                 <header className='text-2xl font-semibold'>Assessment</header>
//                 <SessionTextArea
//                     label="Interventions"
//                     name='assessment_interventions'
//                     placeholder="Note your interventions here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.assessment_interventions}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//
//                 <SessionTextArea
//                     label="Progress and Response"
//                     name='assessment_progress'
//                     placeholder="Note the responses here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.assessment_progress}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//                 <SessionTextArea
//                     label="Observations"
//                     name='assessment_observations'
//                     placeholder="Note your observations here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.assessment_observations}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//             </section>
//
//             <section className='w-full flex items-start flex-col gap-3'>
//                 <header className='text-2xl font-semibold'>Subjective</header>
//                 <SessionTextArea
//                     label="History of Presenting complaint"
//                     name='subjective_history'
//                     placeholder="History of complaint here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.subjective_history}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//
//                 <SessionTextArea
//                     label="Psychological Factors"
//                     name='subjective_psychological'
//                     placeholder="Note psychological factors here..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.subjective_psychological}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//                 <SessionTextArea
//                     label="Relevant Personal and Social History"
//                     name='subjective_relevant'
//                     placeholder="Social history..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.subjective_relevant}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//             </section>
//
//             <section className='w-full flex items-start flex-col gap-3'>
//                 <header className='text-2xl font-semibold'>Objective</header>
//                 <SessionTextArea
//                     label="Appearance"
//                     name='objective_appearance'
//                     placeholder="Note appearance..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.objective_appearance}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//
//                 <SessionTextArea
//                     label="Behavior"
//                     name='objective_behavior'
//                     placeholder="Behavior..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.objective_behavior}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//                 <SessionTextArea
//                     label="Symptoms Observed"
//                     name='objective_symptoms'
//                     placeholder="Symptoms observed..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.objective_symptoms}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//                 <SessionTextArea
//                     label="Risk Assessment"
//                     name='objective_risk'
//                     placeholder="Risk assessment..."
//                     onChange={handleInputChange}
//                     rows={5}
//                     value={note.objective_risk}
//                     onBlur={(e) => handleCreateSessionNote(e)}
//                 />
//             </section>
//         </main>
//     </form>
//   )
// }
//
// export default SessionNote
//
// // eslint-disable-next-line react/prop-types
// const SessionTextArea = ({ label, name, placeholder, rows, value, onChange, onBlur }) => {
//     return(
//         <label className='w-full flex flex-col gap-2'>
//             <label className='font-medium text-sm'>{label}</label>
//             <Textarea
//                 style={{
//                     outline: "none",
//                     border: "1.5px solid #E2E4E9",
//                     boxShadow: "none",
//                 }}
//                 type="text"
//                 name={name}
//                 value={value}
//                 onChange={onChange}
//                 onBlur={onBlur}
//                 rows={rows}
//                 className="placeholder:text-sm focus:outline-none rounded-xl w-full text-xs text-tblack-100"
//                 placeholder={placeholder}
//             />
//         </label>
//     )
// }