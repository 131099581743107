import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, NavLink, Outlet} from "react-router-dom";
import logo from "../assets/Assets/Svgs/logo.svg";
import menu from "../assets/Assets/Svgs/menuIcon.svg";
// import menu from "";
import bellIcon from "./Images/bell-icon.png";
import clientIcon from "./Images/client-icon.png";
import messageIcon from "./Images/message-icon.png";
import appointIcon from "./Images/appoint-icon.png";
import resourceIcon from "./Images/resource-icon.png";
import moreIcon from "./Images/more-icon.png";
import dropIcon from "./Images/drop-icon.png";
import hipa from "./Images/hipa.png";
import iso from "./Images/iso.png";
import dha from "./Images/dha.png";
import Facebook from "./Images/Facebook.png";
import Linkedin from "./Images/Linkedin.png";
import X from "./Images/X.png";
import {AnimatePresence, motion} from "framer-motion";
import "./dashboard.css";
import manageIcon from "./Images/manage.png";
import logoutIcon from "./Images/logout.png";

import {Popover, PopoverContent, PopoverTrigger,} from "@/Components/ui/popover";
import useLogout from "./components/hooks/useLogout";
import {useSettingsDataQuery} from "./services/settingsApiSlice";
import {Skeleton} from "@/Components/ui/skeleton";
import {apiSlice} from "./api/apiSlice";
import {AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogHeader, AlertDialogTitle,} from "@/Components/ui/alert-dialog";
import NotificationPopOver from "./components/Notification/notificationpopover";
import {messageVariants} from "./pages/Messages";
import {useOnOutsideClick} from "./components/hooks/useOutsideClick";
import SessionInfo from "./components/modals/Sessions/SessionInfo";
import {useGetAllNotificationsQuery} from "./services/notificationSlice";
import {useUnreadMessagesQuery} from "./services/dashboardApiSlice";
import Title from "@/Components/Title";
import Pusher from "pusher-js";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "./services/authSlice";

function Wrapper() {

    const token = useSelector(selectCurrentToken);
    const logout = useLogout();
    const notifyRef = useRef();
    const [openPopover, setPopover] = useState(false);
    const [openLogOutModal, setLogOutModal] = useState(false);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [userData, setUserData] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [sessionDetails, setSessionDetails] = useState(null);
    const baseUrl = import.meta.env.VITE_BASE_API_URL;

    const {data: notifications, refetch: refetchNotifications, isUninitialized: notifyIsUninitialized} = useGetAllNotificationsQuery();
    const {data: unreadMessages, refetch: refetchUnreadMessages, isUninitialized} = useUnreadMessagesQuery()
    const unreadItems = notifications?.data?.filter((item) => item.read_at === null);

    console.log(unreadMessages)

    const prefetchMessages = apiSlice.usePrefetch("getConversations");

    const {
        data: profileData,
        error: profileError,
        isLoading,
    } = useSettingsDataQuery();

    useOnOutsideClick(notifyRef, () => {
        setOpenNotifications(() => false);
    });

    useEffect(() => {
        if (!profileError && !isLoading) {
            setUserData(profileData.data);
        } else {
            console.log("Error fetching your data");
        }
    }, [profileData, profileError, isLoading]);


    const connectToPusher = useCallback(() => {
        const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
            cluster: "mt1",
            encrypted: true,
            authEndpoint: `${import.meta.env.VITE_BASE_API_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        });

        const channel = pusher.subscribe('therapist-notification');

        channel.bind('refresh-message', (data) => {
            console.log('Pusher notifications 🔔', data);
            !isUninitialized && refetchUnreadMessages();

            if (Notification.permission === 'granted') {
                const title = 'New Notification';
                const body = data?.data?.message || 'You have received a new message. Check it out!';
                const options = {
                    body,
                    icon: bellIcon,
                    timestamp: Date.now(),
                    renotify: true,
                };
                const notification = new Notification(title, options);

                notification.addEventListener('click', (event) => {
                    if (event.action === 'view') {
                        window.open(`${baseUrl}/dashboard/appointments`);
                    } else if (event.action === 'dismiss') {
                        notification.close();
                    }
                });

                setTimeout(() => {
                    notification.close();
                }, 50000);
            }
        });

        channel.bind('refresh-notification', (data) => {
            console.log('Pusher notifications 📣', data);
            !notifyIsUninitialized && refetchNotifications();

            if (Notification.permission === 'granted') {
                const title = 'New Notification';
                const body = data?.data?.message || 'You have received a new message. Check it out!';
                const options = {
                    body,
                    icon: bellIcon,
                    timestamp: Date.now(),
                    renotify: true,
                };
                const notification = new Notification(title, options);

                notification.addEventListener('click', (event) => {
                    if (event.action === 'view') {
                        window.open(`${baseUrl}/dashboard/appointments`);
                    } else if (event.action === 'dismiss') {
                        notification.close();
                    }
                });

                setTimeout(() => {
                    notification.close();
                }, 50000);
            }
        });

        pusher.connection.bind('error', (error) => {
            console.error('Pusher connection error:', error);
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, [token, refetchUnreadMessages, refetchNotifications]);


    useEffect(() => {
        return connectToPusher();
    }, [connectToPusher]);


    useEffect(() => {
        if (Notification.permission === 'default') {
            Notification.requestPermission();
        }
    }, []);


    const handleClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    function handleCloseMenu() {
        setIsDropdownVisible(false);
    }

    function handleLogout() {
        logout();
        setPopover(false);
        setIsDropdownVisible(false);
    }

    const variants = {
        initial: {
            x: "70vw",
        },
        animate: {
            x: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.5,
            },
        },
        exit: {
            x: "70vw",
            transition: {
                ease: "easeOut",
                duration: 0.5,
            },
        },
    };

    return (
        <div className="h-[100%] wrapper min-h-screen ">
            <Title title={"Therapist"}/>
            <SessionInfo
                openModal={openModal}
                setModal={setOpenModal}
                sessionId={sessionDetails}
            />
            <AlertDialog open={openLogOutModal} onOpenChange={setLogOutModal}>
                <AlertDialogContent
                    style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
                    className="w-[90vw] max-h-screen overflow-y-scroll max-w-[440px] duo:p-6 rounded-[16px]"
                >
                    <AlertDialogHeader>
                        <AlertDialogTitle className="text-center mb-1 text-[#0A0D14]">
                            Confirm Logout
                        </AlertDialogTitle>
                        <AlertDialogDescription className="text-center text-[#525866] ">
                            Are you sure you want to logout? Logging out will end your current
                            session. If you have any unsaved changes, make sure to save them
                            before proceeding.
                        </AlertDialogDescription>
                    </AlertDialogHeader>

                    <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mb-1 mt-2"/>
                    <div className="flex w-full items-end gap-[2%] justify-center">
                        <AlertDialogCancel
                            className=" rounded-[50px] text-[#525866] cursor-pointer border border-[#E2E4E9] text-[13px] duo:text-[14px] font-meduim leading-5 w-[49%]  py-2 tablet:py-[10px] ">
                            Cancel
                        </AlertDialogCancel>
                        <button
                            onClick={handleLogout}
                            className=" rounded-[50px]  h-[37px]  tablet:h-[41px] cursor-pointer bg-[#DF1C41] text-[13px] duo:text-[14px] leading-[14px] w-[49%] duo:leading-[15px] font-meduim  text-white flex justify-center items-center"
                        >
                            Log Out
                        </button>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
            <div className="bg-white border-b border-[#E2E4E9] px-[5vw] sticky top-0 z-50 flex justify-between items-center py-4 laptop:py-[22px]">
                <div className="text-[#525866] flex justify-start items-center gap-10">
                    <Link to="home">
                        <img
                            src={logo}
                            alt="logo"
                            className="w-[28vw] max-w-[150px] tablet:max-w-[170px]"
                        />
                    </Link>
                    <div className="hidden laptop:flex justify-start items-center gap-0 *:py-2 *:px-4 *:rounded-[8px]">
                        <NavLink
                            to="messages"
                            className={({isActive}) =>
                                `flex gap-2 transition-all duration-500 ease-in-out  justify-start items-center ${
                                    isActive ? "is-active bg-[#F6F8FA]" : ""
                                }`
                            }
                            onMouseEnter={() => prefetchMessages()}
                        >
                            <img src={messageIcon} alt="messages" className="w-4"/>
                            <div className="flex justify-start items-center">
                                <p className=" m-0 font-medium text-[14px] leading-5 ">
                                    Messages
                                </p>
                            </div>
                            {unreadMessages?.data.length > 0 ? <div className="h-[16px] px-[4px] bg-[#DF1C41] rounded-full grid place-items-center  min-w-[16px] text-center">
                                <p className="text-[10px] leading-3 font-medium text-white ">
                                    {unreadMessages?.data.length ?? 0}
                                </p>
                            </div> : null}
                        </NavLink>
                        <NavLink
                            to="appointments"
                            className={({isActive}) =>
                                `flex gap-2 transition-all duration-500 ease-in-out  justify-start items-center ${
                                    isActive ? "is-active bg-[#F6F8FA]" : ""
                                }`
                            }
                        >
                            <img src={appointIcon} alt="messages" className="w-4"/>
                            <div className="flex justify-start items-center">
                                <p className=" m-0 font-medium text-[14px] leading-5 ">
                                    Appointments
                                </p>
                            </div>
                            {/* <div className="h-[16px] px-[4px] bg-[#DF1C41] rounded-full grid place-items-center  min-w-[16px] text-center">
                <p className="text-[10px] leading-3 font-medium text-white ">
                  3
                </p>
              </div> */}
                        </NavLink>
                        <NavLink
                            to="client_list"
                            className={({isActive}) =>
                                `flex gap-2 transition-all duration-500 ease-in-out  justify-start items-center ${
                                    isActive ? "is-active bg-[#F6F8FA]" : ""
                                }`
                            }
                        >
                            <img src={clientIcon} alt="messages" className="w-4"/>

                            <div className="flex justify-start items-center">
                                <p className=" m-0 font-medium text-[14px] leading-5 ">
                                    Client List
                                </p>
                            </div>
                        </NavLink>
                        <NavLink
                            to="resources"
                            className={({isActive}) =>
                                `flex gap-2 transition-all duration-500 ease-in-out  justify-start items-center ${
                                    isActive ? "is-active bg-[#F6F8FA]" : ""
                                }`
                            }
                        >
                            <img src={resourceIcon} alt="messages" className="w-5"/>
                            <div className="flex justify-start items-center">
                                <p className=" m-0 font-medium text-[14px] leading-5 ">
                                    Resources
                                </p>
                            </div>
                        </NavLink>
                        <NavLink
                            to="do_more"
                            className={({isActive}) =>
                                `flex gap-2 transition-all duration-500 ease-in-out  justify-start items-center ${
                                    isActive ? "is-active bg-[#F6F8FA]" : ""
                                }`
                            }
                        >
                            <img src={moreIcon} alt="messages" className="w-5"/>
                            <div className="flex justify-start items-center">
                                <p className=" m-0 font-medium text-[14px] leading-5 ">
                                    How to Do More
                                </p>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="hidden laptop:flex justify-end items-center gap-6">
                    {/*<div className=" hidden min-[1400px]:inline">*/}
                    {/*    <img src={messageIcon} alt="messages" className="w-[15px]"/>*/}
                    {/*</div>*/}

                    {/*TODO*/}
                    <div className="relative cursor-pointer">
                        <img onClick={() => setOpenNotifications(prev => !prev)} src={bellIcon} alt="notifications" className="w-[15px]"/>
                        {
                            openNotifications ?
                                <motion.div
                                    ref={notifyRef}
                                    key="notify"
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    variants={messageVariants}
                                    className={`absolute right-0 pt-2`}
                                >
                                    <NotificationPopOver
                                        setOpenModal={setOpenModal}
                                        setSessionDetails={setSessionDetails}
                                        close={() => setOpenNotifications(prev => !prev)}
                                    />
                                </motion.div>
                                :
                                null
                        }
                        {unreadItems?.length > 0 ? <div className="h-[16px] px-[4px] bg-[#DF1C41] rounded-full grid place-items-center min-w-[16px] text-center absolute -top-3 left-2">
                            <p className="text-[10px] leading-3 font-medium text-white ">
                                {unreadItems?.length ?? 0}
                            </p>
                        </div> : null}
                    </div>

                    <Popover open={openPopover} onOpenChange={setPopover}>
                        <PopoverTrigger>
                            {userData ? (
                                <div className="flex justify-center items-center pl-[4px] py-1 pr-4 border border-[#E2E4E9] rounded-[10px] gap-[8px]">
                                    <img
                                        src={userData.avatar_id}
                                        alt="avartar"
                                        className="w-8 rounded-[100%] aspect-square"
                                    />
                                    <p className=" m-0 text-[#0A0D14] text-[14px] capitalize leading-5 font-medium">
                                        {userData?.full_name.split(" ")[0]}
                                    </p>

                                    <img src={dropIcon} className="w-[9px]" alt="open"/>
                                </div>
                            ) : (
                                <div className="flex justify-center items-center pl-[4px] py-1 pr-2 border border-[#E2E4E9] rounded-[10px] gap-[8px]">
                                    <Skeleton className="size-8 rounded-[100%]"/>
                                    <Skeleton className="w-16 h-4 rounded-[50px]"/>
                                    <img src={dropIcon} className="w-[9px]" alt="open"/>
                                </div>
                            )}
                        </PopoverTrigger>
                        <PopoverContent className="w-fit bg-transparent hidden laptop:block shadow-none border-none outline-none pt-1 pb-0 pl-0 pr-[0.5vw]">
                            <div className="w-[252px] bg-white border border-[#E2E4E9] p-2 rounded-[16px]">
                                {userData ? (
                                    <div className="flex p-2 justify-start items-center gap-4">
                                        <img
                                            className="w-[40px] aspect-square rounded-[100%]"
                                            src={userData.avatar_id}
                                            alt="icon"
                                        />
                                        <div className="flex flex-col justify-between items-start">
                                            <h2 className="text-[#0A0D14] capitalize text-[14px] leading-5 font-medium">
                                                {userData.full_name}
                                            </h2>
                                            <p className="text-[#525866] text-[12px] leading-4">
                                                {userData.email_address}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex p-2 justify-start items-center gap-4">
                                        <Skeleton className="size-10 rounded-[100%]"/>

                                        <div className="flex flex-col justify-between items-start">
                                            <Skeleton className="w-[90px] h-3 rounded-[50px] mb-2"/>

                                            <Skeleton className="w-16 h-3 rounded-[50px]"/>
                                        </div>
                                    </div>
                                )}
                                <hr className="w-full border-none bg-[#E2E4E9] h-[1px] my-[6px]"/>
                                <Link
                                    onClick={() => setPopover(false)}
                                    to="settings/personal_info"
                                >
                                    <div className="p-2 flex justify-start items-center gap-2">
                                        <img className="w-[20px]" src={manageIcon} alt="logout"/>
                                        <h2 className="text-[#0A0D14] text-[14px] leading-5">
                                            Manage Account
                                        </h2>
                                    </div>
                                </Link>
                                <div
                                    onClick={setLogOutModal}
                                    className="p-2 flex justify-start items-center gap-2 cursor-pointer"
                                >
                                    <img className="w-[20px]" src={logoutIcon} alt="logout"/>
                                    <h2 className="text-[#0A0D14] text-[14px] leading-5">
                                        Logout
                                    </h2>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                {isDropdownVisible && (
                    <div
                        onClick={() => setIsDropdownVisible(false)}
                        className="bg-black opacity-50 z-0 left-0 block w-screen h-screen top-0 fixed laptop:hidden"
                    ></div>
                )}
                <div className="flex laptop:hidden justify-end w-[30%]">
                    <img
                        alt="menu"
                        onClick={handleClick}
                        className="menu cursor-pointer"
                        src={menu}
                    />
                    <AnimatePresence>
                        {isDropdownVisible && (
                            <motion.div
                                key="mobmenu"
                                variants={variants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="bg-white  text-[16px] tablet:text-[18px]  py-6 tablet:py-8  min-w-[60vw] w-[40%] duo:min-w-[300px] mt-[15%] tablet:mt-[10%] ml-[-32%] flex-col flex laptop:hidden absolute shadow-xl rounded-[10px]"
                            >
                                {/* <div className="flex px-5 tablet:px-7 w-full justify-center items-center pl-[4px] py-1 pr-2   gap-2 tablet:gap-2">
                  <img src={avatar} alt="avartar" className="w-8 tablet:w-10 mr-2" />
                  <p className=" m-0 text-[#0A0D14] text-[14px] tablet:text-[16px]  leading-5 font-medium">
                    Nour
                  </p>
                  <img src={dropIcon} className="w-[9px] tablet:w-[11px]" alt="open" />
                </div> */}
                                {/* <hr className="w-full border-none bg-[#E2E4E9] h-[1px] mt-4 mb-4" /> */}

                                <div className="w-full overflow-hidden bg-transparent  ">
                                    {userData ? (
                                        <div className="flex px-5  tablet:px-7 justify-start items-center  gap-4">
                                            <img
                                                className="w-[40px] tablet:w-[48px] aspect-square rounded-[100%]"
                                                src={userData.avatar_id}
                                                alt="icon"
                                            />
                                            <div className="flex flex-col justify-between items-start">
                                                <h2 className="text-[#0A0D14] text-[14px] tablet:text-[16px]  leading-5 font-medium">
                                                    {userData.full_name}
                                                </h2>
                                                <p className="text-[#525866] text-[12px] tablet:text-[14px]  leading-4">
                                                    {userData.email_address}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex px-5 w-full  tablet:px-7 justify-start items-center  gap-4">
                                            <Skeleton className="size-10 tablet:size-12 rounded-[100%]"/>

                                            <div className="flex flex-col justify-between w-[72%] tablet:w-[70%] items-start">
                                                <Skeleton className="w-[90%] h-3 tablet:h-4 rounded-[50px] mb-2"/>

                                                <Skeleton className="w-[40%] h-3 tablet:h-4 rounded-[50px]"/>
                                            </div>
                                        </div>
                                    )}
                                    <hr className="w-full border-none bg-[#E2E4E9] h-[1px] mt-6 mb-6"/>
                                    <div className="flex flex-col px-5 tablet:px-7 justify-start  items-start gap-6 tablet:gap-8 ">
                                        <Link
                                            to="settings/personal_info"
                                            onClick={handleCloseMenu}
                                            className=" flex justify-start items-center gap-2 tablet:gap-3"
                                        >
                                            <img
                                                className="w-[22px] tablet:w-[26px]"
                                                src={manageIcon}
                                                alt="logout"
                                            />
                                            <h2 className="text-[#0A0D14] text-[14px] tablet:text-[16px] leading-5">
                                                Manage Account
                                            </h2>
                                        </Link>
                                        <div
                                            onClick={setLogOutModal}
                                            className=" flex cursor-pointer justify-start items-center gap-2 tablet:gap-3"
                                        >
                                            <img
                                                className="w-[22px] tablet:w-[26px]"
                                                src={logoutIcon}
                                                alt="logout"
                                            />
                                            <h2 className="text-[#0A0D14] text-[14px] tablet:text-[16px] leading-5">
                                                Logout
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-full bg-[#E2E4E9] border-none h-[1px] mt-6 mb-6"/>
                                <div
                                    className="flex  flex-col justify-start items-start px-[10px] *:px-[10px] tablet:px-[14px] *:tablet:px-[14px] *:rounded-[6px] gap-2 *:py-2 *:tablet:py-3 *:transition-all *:duration-300 *:ease-in-out *:delay-700  *:w-full">
                                    <NavLink
                                        onClick={handleCloseMenu}
                                        to="messages"
                                        className={({isActive}) =>
                                            `flex gap-2 tablet:gap-3 justify-start items-center ${
                                                isActive ? "is-active bg-[#F6F8FA]" : ""
                                            }`
                                        }
                                        onMouseEnter={() => prefetchMessages()}
                                    >
                                        <img
                                            src={messageIcon}
                                            alt="messages"
                                            className="w-4 tablet:w-5"
                                        />
                                        <div className="flex justify-start items-center">
                                            <p className=" m-0 font-medium text-[14px] tablet:text-[16px] leading-5 ">
                                                Messages
                                            </p>
                                        </div>
                                        {/* <div className="h-[16px] px-[4px] bg-[#DF1C41] rounded-full grid place-items-center  min-w-[16px] text-center">
                      <p className="text-[10px] leading-3 font-medium text-white ">
                        5
                      </p>
                    </div> */}
                                    </NavLink>
                                    <NavLink
                                        onClick={handleCloseMenu}
                                        to="appointments"
                                        className={({isActive}) =>
                                            `flex gap-2 tablet:gap-3 justify-start items-center ${
                                                isActive ? "is-active bg-[#F6F8FA]" : ""
                                            }`
                                        }
                                    >
                                        <img
                                            src={appointIcon}
                                            alt="messages"
                                            className="w-4 tablet:w-5"
                                        />
                                        <div className="flex justify-start items-center">
                                            <p className=" m-0 font-medium text-[14px] tablet:text-[16px] leading-5 ">
                                                Appointments
                                            </p>
                                        </div>
                                        {/* <div className="h-[16px] px-[4px] bg-[#DF1C41] rounded-full grid place-items-center  min-w-[16px] text-center">
                      <p className="text-[10px] leading-3 font-medium text-white ">
                        3
                      </p>
                    </div> */}
                                    </NavLink>

                                    <NavLink
                                        onClick={handleCloseMenu}
                                        to="client_list"
                                        className={({isActive}) =>
                                            `flex gap-2 tablet:gap-3 justify-start items-center ${
                                                isActive ? "is-active bg-[#F6F8FA]" : ""
                                            }`
                                        }
                                    >
                                        <img
                                            src={clientIcon}
                                            alt="messages"
                                            className="w-4 tablet:w-5"
                                        />

                                        <div className="flex justify-start items-center">
                                            <p className=" m-0 font-medium text-[14px] tablet:text-[16px] leading-5 ">
                                                Client List
                                            </p>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        onClick={handleCloseMenu}
                                        to="resources"
                                        className={({isActive}) =>
                                            `flex gap-2 tablet:gap-3 justify-start items-center ${
                                                isActive ? "is-active bg-[#F6F8FA]" : ""
                                            }`
                                        }
                                    >
                                        <img
                                            src={resourceIcon}
                                            alt="messages"
                                            className="w-5 tablet:w-[24px]"
                                        />
                                        <div className="flex justify-start items-center">
                                            <p className=" m-0 font-medium text-[14px] tablet:text-[16px] leading-5 ">
                                                Resources
                                            </p>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        onClick={handleCloseMenu}
                                        to="do_more"
                                        className={({isActive}) =>
                                            `flex gap-2 tablet:gap-3 justify-start items-center ${
                                                isActive ? "is-active bg-[#F6F8FA]" : ""
                                            }`
                                        }
                                    >
                                        <div
                                            onClick={handleCloseMenu}
                                            className="flex gap-2 tablet:gap-3 justify-start items-center"
                                        >
                                            <img
                                                src={moreIcon}
                                                alt="messages"
                                                className="w-5 tablet:w-[24px]"
                                            />
                                            <div className="flex justify-start items-center">
                                                <p className=" m-0 font-medium text-[14px] tablet:text-[16px] leading-5 ">
                                                    How to Do More
                                                </p>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
            <div className="px-[5vw] min-[1500px]:px-[8vw] pt-6 tablet:pt-8 pb-6  bg-transparent">
                <Outlet/>
            </div>
            <div className="px-[5vw] min-[1500px]:px-[8vw] pt-7 border-t border-[#E2E4E9] pb-8 tablet:py-[48px] sticky top-full w-full bg-white">
                <div className="flex justify-center items-center gap-5 flex-col tablet:flex-row tablet:justify-between tablet:items-start">
                    <div className="flex justify-center items-center gap-6  *:text-[14px] *:font-medium *:leading-5 *:text-[#525866] ">
                        <Link to="/Faq">FAQs</Link>
                        <Link to="/terms-and-conditions">Terms of use</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                    <div className="flex  justify-center items-center gap-4 *:h-[55px] *:min-[380px]:h-[65px]">
                        {/*<img src={dha} alt="dubai health authority"/>*/}
                        <img src={hipa} alt="hipaa"/>
                        {/*<img src={iso} alt="iso"/>*/}
                    </div>
                </div>
                <hr className="w-full mt-7 border-none h-[1px] bg-[#EAECF0]"/>
                <div className="flex flex-col justify-center items-center gap-3 mt-5 tablet:mt-9 tablet:flex-row tablet:justify-between tablet:items-end">
                    <p className="text-[1rem] leading-6 font-normal text-[#667085]">
                        © {currentYear} Mentra. All rights reserved.
                    </p>
                    <div className="flex  justify-center items-center gap-6">
                        <a href="http://" target="_blank" rel="noopener noreferrer">
                            <img src={Linkedin} alt="linkedin" className="w-6"/>
                        </a>
                        <a
                            href="https://www.facebook.com/yourmentra"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={Facebook} alt="facebook" className="w-6"/>
                        </a>
                        <a href="http://" target="_blank" rel="noopener noreferrer">
                            <img src={X} alt="X" className="w-6"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wrapper;
