import axios from "axios";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import Loader from "@/Components/loader";
import {useEndSessionMutation} from "@/App/services/sessionSlice.js";
import './custom.css';
import SessionNote from "@/App/components/videoChatPage/sessionnote.jsx";
import useAudioRecording from "@/App/components/videoChatPage/AudioRecorder.jsx";

function VideoScreen() {
    const sessionContainerRef = useRef(null);
    const [currentTime, setCurrentTime] = useState('');
    const [sessionData, setSessionData] = useState(null);
    const [sessionJoined, setSessionJoined] = useState(false);
    const [endSession, {isLoading: endLoading}] = useEndSessionMutation();
    const navigate = useNavigate();
    const {id} = useParams();
    const baseUrl = import.meta.env.VITE_BASE_API_URL;
    const [showNotes, setShowNotes] = useState(false);
    const [isButtonVisible, setButtonVisible] = useState(false);
    // Recording
    const {isSaving, startRecording, stopRecording} = useAudioRecording(baseUrl, id);


    // Fetch session data by ID
    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await axios.get(`${baseUrl}/session/?id=${id}`);
                setSessionData(response.data);
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };
        if (id) fetchSession();
    }, [id, baseUrl]);

    // Update time every second
    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString());
        };
        const intervalId = setInterval(updateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);


    // Function to handle ending the session
    const handleEndSession = async (sessionId, reason) => {
        try {
            await endSession({id: sessionId, reason: {reason}}).unwrap();
            stopRecording()
            // save an audio file
            // if (!isSaving) navigate("/dashboard/home");
            navigate("/dashboard/home")

        } catch (error) {
            console.error("Failed to end session:", error);
        }
    };

    const authEndpoint = `${baseUrl}/zoom-sdk-auth`;
    const config = {
        videoSDKJWT: "",
        sessionName: sessionData?.data?.reference || "",
        userName: sessionData?.data?.therapist.name,
        sessionPasscode: "",
        // features: ["video", "audio", "settings", "users", "chat"],
        features: ["video", "audio"],
        options: {
            init: {},
            audio: {},
            video: {
                originalRatio: true

            },
        },
    };
    const role = 1; // 1 for host, 2 for participant

    // Fetch Video SDK JWT and join session
    const getVideoSDKJWT = () => {
        if (!sessionData || sessionJoined || !sessionContainerRef.current) return;

        fetch(authEndpoint, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({sessionName: config.sessionName, role}),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.signature) {
                    console.log(data.signature);
                    config.videoSDKJWT = data.signature;
                    joinSession();
                } else {
                    console.log(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    // Change the view to gallery after the Dom is fully loaded.
    uitoolkit.onSessionJoined(() => {
        setTimeout(() => {
            const changeViewButton = document.querySelector('[aria-label="Change view"]');
            if (changeViewButton) {
                changeViewButton.click();

                const galleryViewButton = [...document.querySelectorAll("span")].find((span) =>
                    span.textContent?.includes("Gallery")
                );

                if (galleryViewButton) {
                    galleryViewButton.click();
                    console.log("View switched to Gallery.");
                } else {
                    console.warn("Gallery view option not found.");
                }
            } else {
                console.warn("Change view button not found.");
            }
            setButtonVisible(true)
        }, 1000);
    });


    // Join the session using UI Toolkit
    const joinSession = () => {
        if (sessionJoined || !config.videoSDKJWT || !sessionContainerRef.current)
            return;

        uitoolkit.joinSession(sessionContainerRef.current, config);
        setSessionJoined(true);

        uitoolkit.onSessionJoined(() => console.log('Session joined'));

        uitoolkit.onSessionClosed(async () => {
            console.log('Session closed 👍');

            handleEndSession(id, 'Session ended by the host').then(r => {
                console.log("Session ended successfully:", r);
            });
            setSessionJoined(false);
        });
    };

    // Trigger JWT fetch and join when sessionData is available
    useEffect(() => {
        if (sessionData) {
            getVideoSDKJWT();
        }
        return () => {
            uitoolkit.offSessionJoined(() => setSessionJoined(false));
            uitoolkit.offSessionClosed(() => setSessionJoined(false));
        };
    }, [sessionData]);


    // Start recording after three seconds on the meetings
    useEffect(() => {
        const timer = setTimeout(() => {
            startRecording().then(r => console.log("Recording started...", r));
        }, 15000);

        return () => clearTimeout(timer);
    }, []);


    const toggleNotes = () => {
        setShowNotes((prev) => !prev);
    };

    return (
        <>
            {endLoading || isSaving ? (
                // Loader section
                <div className="loader-container absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                    <Loader/>
                </div>
            ) : (
                // Main content when loading is complete
                <div className="video-screen relative">
                    {/* Note-Taker Section */}
                    {showNotes && (
                        <aside className="fixed top-0 left-0 w-[30vw] h-full bg-gray-100 p-4 overflow-y-auto z-40 shadow-lg">
                            <SessionNote sessionId={sessionData?.data?.id}/>
                        </aside>
                    )}


                    {/* Main Video Session Section */}
                    <main className="relative">
                        <header className="header flex justify-between items-center h-16 text-white px-4 md:px-12">
                            <p className="cursor-pointer text-xl font-semibold">Mentra</p>
                            <p className="bg-slate-700 rounded-lg p-2">{currentTime}</p>
                        </header>

                        <section className="session">
                            <div ref={sessionContainerRef} id="sessionContainer" className=""></div>
                        </section>
                        {/* Take Note Button */}
                        {isButtonVisible && (
                            <button
                                id="notetaker"
                                onClick={toggleNotes}
                                className={`ml-[35vw] mb-2 capitalize text-white z-40 text-[16px] px-4 py-2 rounded-[6px] shadow-md leading-[17px] ${
                                    showNotes ? 'bg-[#e8163c]' : 'bg-[#1A6A73]'
                                }`}
                            >
                                {showNotes ? 'Close Note' : 'Take Note'}
                            </button>
                        )}
                    </main>
                </div>
            )}
        </>
    );
}

export default VideoScreen;