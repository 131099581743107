import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import {Provider} from 'react-redux';
import {store} from './App/api/store.js';
import {Toaster} from 'sonner';
import {AnimatePresence} from 'framer-motion';

ReactGA.initialize("G-4CWQZP1SD1");
ReactGA.send({hitType: "pageview", page: window.location.pathname});

Sentry.init({
    dsn: "https://6eccade77e769a465edcb45e0e681e86@o915393.ingest.us.sentry.io/4507050194894848",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <Provider store={store}>
            <AnimatePresence mode='wait'>
                <App/>
            </AnimatePresence>
        </Provider>
        <Toaster richColors position="top-right"/>
    </>,
)
